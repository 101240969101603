<template>
    <div class="import-csv">
        
        <h6>Select a file to import</h6>
        <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv, .txt" />

        <v-select
            :attach="true"
            :items="entities"
            v-model="selectedEntity"
            label="Select the type of data your are importing"
            required
            outlined
            color="success"
            @change="createMappingTable">
        </v-select>

        <v-select
            :attach="true"
            :items="fieldDelimiters"
            v-model="selectedFieldDelimiter"
            label="Select the field delimiter used in your file"
            required
            outlined
            color="success"
            @change="createMappingTable">
        </v-select>

        <h6 v-if="inputColumns.length" >Tell us what each column in your text file should map to</h6>
        <div v-if="inputColumns.length" class="table-responsive">
            <table class="table table-striped">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Input Column Name</th>
                        <th scope="col">Map To {{ this.selectedEntity }} Field</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(column, index) in inputColumns" :key="index" scope="row">
                        <td>{{ column.inputHeaderName }}</td>
                        <td>
                            <v-select
                                :items="fields[selectedEntity]"
                                v-model="column.mappedFieldName"
                                label="Select field"
                                outlined>
                            </v-select>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>

        <div>
            <button type="button" class="btn btn-primary" @click="performImport" :disabled="isImporting">
            <span v-if="!isImporting">Import</span>
            <span v-else><i class="fas fa-spinner fa-spin"></i> Importing...</span>
            </button>
        </div>

        <div v-if="importErrors" class="alert alert-danger">
            <h6>Import Errors</h6>
            <pre>{{ importErrors }}</pre>
        </div>
    </div>
  </template>
  
  <script>
  import ApiManager from "@/api/ApiManager";
  import * as Utils from "@/utils/Utils";
  import * as Logs from "@/utils/Logs";
  import * as Session from "@/utils/SessionStore"

  export default {
    name: 'ImportCsv',
    data() {
      return {
        fileFirstLine: '',
        importErrors: null,
        fieldDelimiters:[
            { value: ':', text: 'Colon (:)' },
            { value: ',', text: 'Comma (,)' },
            { value: '|', text: 'Pipe (|)' },
            { value: ';', text: 'Semi-colon (;)' },
            { value: '\t', text: 'TAB' },
        ],
        selectedFieldDelimiter: '\t',
        file: null,
        isImporting: false,
        entities:[
            { value: "employee", text:"Employees" },
            { value: "leave",    text:"Leave" },
            { value: "payroll",  text:"Payroll" }
        ],
        selectedEntity: 'employee',
        inputColumns:[],

        /** these are fields in our backend against a human readable label */
        fields: {
            leave:[
                { value: '', text: 'N/A' },
                { value: 'EmployeeId', text: 'Employee Number' },
                { value: 'EmployeeEmail', text: 'Employee Email' },
                { value: 'EndDate', text: 'End Date' },
                { value: 'FirstName', text: 'First Name' },
                { value: 'LastName', text: 'Last Name' },
                { value: 'Type', text: 'Leave Type' },
                { value: 'Note', text: 'Notes' },
                { value: 'StartDate', text: 'Start Date' },
                { value: 'Status', text: 'Status' },
                { value: 'TotalHours', text: 'Total Hours' },
            ],
            payroll: [
                { value: '', text: 'N/A' },

                { value: 'AmountPaid', text: 'Amount Paid' },
                { value: 'ContributionRate', text: 'Contribution Rate' },

                { value: 'Earnings', text: 'Earnings' },
                { value: 'EarningsSuper', text: 'Earnings Super' },
                { value: 'Email', text: "Employee's Email" },
                { value: 'TFN', text: "Employee's Tax File Number" },
                { value: 'EmployeeId', text: 'Employee Number' },
                
                
                { value: 'FirstName', text: 'First Name' },

                { value: 'EofyFinal', text: 'Is EOFY Final' },

                { value: 'LastName', text: 'Last Name' },

                { value: 'NetPay', text: 'Net Pay' },

                { value: 'Reimbursement', text: 'Reimbursement' },

                { value: 'OrdinaryWorkHours', text: 'Ordinary Work Hours' },
                { value: 'OrdinaryPayRate', text: 'Ordinary Pay Rate' },

                
                { value: 'EndPayPeriod', text: 'Pay Period End Date' },
                { value: 'StartPayPeriod', text: 'Pay Period Start Date' },

                { value: 'PaymentDate', text: 'Payment Date' },
                
                { value: 'Super', text: 'Super' },

                { value: 'Tax', text: 'Tax' },
                { value: 'EarningsTax', text: 'Tax On Earnings' },

                { value: 'TerminationPay', text: 'Termination Pay' },
                { value: 'TotalAllowance', text: 'Total Allowance' },
                { value: 'TotalBonous', text: 'Total Bonus' },
                { value: 'TotalDeduction', text: 'Total Deduction' },
                { value: 'TotalExtraHours', text: 'Total Extra Hours' },
            ],

            employee: [
                { value: '', text: 'N/A' },

                { value: 'Address1', text: 'Address Line 1' },
                { value: 'Address2', text: 'Address Line 2' },
                { value: 'Suburb', text: 'Address Suburb' },
                { value: 'Postcode', text: 'Address Postcode' },
                { value: 'State', text: 'Address State' },
                { value: 'Country', text: 'Address Country' },
                { value: 'AnnualLeaveBalance', text: 'Annual Leave Balance' },

                { value: 'BankAccountName', text: 'Bank Account Name' },
                { value: 'BankAccountBSB', text: 'Bank Account BSB' },
                { value: 'BankAccountNumber', text: 'Bank Account Number' },

                { value: 'CasualLoading', text: 'Casual Loading' },
                { value: 'ContactNumber', text: 'Contact Number' },
                { value: 'ClaimTaxFreeThreshold', text: 'Claim Tax Free Threshold' },
                
                { value: 'BirthDate', text: 'Date of birth' },

                { value: 'Email', text: 'Email' },
                { value: 'EmploymentType', text: 'Employment Type' },
                { value: 'Id', text: 'Employee ID' },

                { value: 'FirstName', text: 'First Name' },
                { value: 'Gender', text: 'Gender' },

                { value: 'HasHecsOrHelpDebt', text: 'Has HECS or HELP Debt' },
                { value: 'HasTslOrStudentStart', text: 'Has TSL or Student Start' },
                { value: 'HasFinancialSupplementDebt', text: 'Has Financial Supplement Debt' },

                { value: 'Job', text: 'Job Title' },
               
                { value: 'LastName', text: 'Last Name' },
                { value: 'LeaveLoading', text: 'Leave Loading' },
                
                { value: 'Notes', text: 'Notes' },

                { value: 'PayRate', text: 'Pay Rate' },
                { value: 'TypeRate', text: 'Pay Per (Hour, Day, etc)' },
                { value: 'PayPeriod', text: 'Pay Period (Weekly, Fornightly, Monthly, etc)' },

                { value: 'PayRatePerHour', text: 'Rate per Hour' },

                { value: 'StatusResidency', text: 'Residency Status' },

                { value: 'PaymentMethod', text: 'Salary Payment Method' },
                { value: 'SickLeaveBalance', text: 'Sick Leave Balance' },
                { value: 'StartDate', text: 'Start Date' },
                
                
                { value: 'ContributionRate', text: 'Super Contribution Rate' },
                { value: 'EmployerContribution', text: 'Super Employer Contribution' },

                { value: 'FundName', text: 'Super Fund Name' },
                { value: 'FundABN', text: 'Super Fund ABN' },
                { value: 'FundAddress', text: 'Super Fund Address' },
                { value: 'SuperFundType', text: 'Super Fund Type' },
                { value: 'FundUSI', text: 'Super Fund USI' },

                { value: 'FundEmployerNumber', text: 'Super Fund Employer Number' },
                { value: 'FundBankName', text: 'Super Fund Bank Name' },
                { value: 'FundBankBSB', text: 'Super Fund Bank BSB' },
                { value: 'FundBankNumber', text: 'Super Fund Bank Account Number' },
                { value: 'MemberNumber', text: 'Super Fund Member Number' },
                
                { value: 'SuperGuarantee', text: 'Super Guarantee Rate' },
                
                { value: 'SuperSalarySacrifice', text: 'Super Salary Sacrifice' },
                
                { value: 'TerminationDate', text: 'Termination Date' },
                { value: 'IsExemption', text: 'Is Exempt From TFN' },
                { value: 'TFNExemption', text: 'TFN Exemption Type' },
                { value: 'TaxNumber', text: 'Tax File Number' },
                
                { value: 'WeeklyWorkHours', text: 'Weekly Work Hours' },
            ],
        },
        
        inputColumnMaps: {
            employee: {
                "FIRST NAME": "FirstName",
                "FNAME": "FirstName",

                "LAST NAME": "LastName",
                "SURNAME": "LastName",
                "LNAME": "LastName",
                
                "EMAIL": "Email",
                "CONTACT NUMBER": "ContactNumber",
                "PHONE": "ContactNumber",
                "ADDRESS LINE 1": "Address1",
                "ADDRESS LINE 2": "Address2",
                "ADDRESS 1": "Address1",
                "ADDRESS 2": "Address2",
                "SUBURB": "Suburb",
                "STATE": "State",
                "POSTCODE": "Postcode",
                "ZIPCODE": "Postcode",
                "ZIP CODE": "Postcode",
                "COUNTRY": "Country",
                "EARNINGS": "Earnings",
                "EARNING": "Earnings",
                "DOB": "BirthDate",
                "BIRTH DATE": "BirthDate",
                "DATE OF BIRTH": "BirthDate",
                "START DATE": "StartDate",
                "DATE START": "StartDate",
                "JOINED DATE": "StartDate",
                "JOIN DATE": "StartDate",

                "TERMINATION DATE": "TerminationDate",
                "TERMINATE DATE": "TerminationDate",

                "GENDER": "Gender",
                "SEX": "Gender",

                "JOB": "Job",
                "JOB TITLE": "Job",

                "PAY RATE": "PayRate",

                "EMPLOYMENT TYPE": "EmploymentType",
                "EMPLOYMENT BASIS": "EmploymentType",

                "TAX FILE NUMBER": "TaxNumber",
                "TAX FILE NO.": "TaxNumber",
                "TFN": "TaxNumber",

                "TFN EXEMPTION": "IsExemption",
                "TFN EXEMPTION TYPE": "TFNExemption",
                "TFN DECLARATION": "",
                "EMPLOYER CONTRIBUTION": "",

                "HAS HECS OR HELP DEBT": "HasHecsOrHelpDebt",
                "HAS FINANCIAL SUPPLEMENT DEBT": "HasFinancialSupplementDebt",
                "HAS TSL OR STUDENT LOAN": "HasTslOrStudentStart",
                "TERMINATION REASON": "",

                "NOTES": "Notes",
                "NOTE": "Notes",
                "COMMENT": "Notes",
                "COMMENTS": "Notes",

                "CASUAL LOADING": "CasualLoading",
                "LEAVE LOADING": "LeaveLoading",
                "CLAIM TAX FREE THRESHOLD": "ClaimTaxFreeThreshold",

                "SALARY PAYMENT METHOD": "PaymentMethod",
                "WAGE PAYMENT METHOD": "PaymentMethod",
                "PAYMENT METHOD": "PaymentMethod",

                "PAY PERIOD": "PayPeriod", // Weekly, Forthnightly, Monthly etc
                "PAY RATE TYPE": "TypeRate", // Per hour, per day etc

                "BANK ACCOUNT NAME": "BankAccountName",
                "BANK ACCOUNT NO.": "BankAccountNumber",
                "BANK ACCOUNT NUMBER": "BankAccountNumber",
                "BANK ACCOUNT": "BankAccountNumber",
                "BANK ACCOUNT BSB": "BankAccountBSB",

                "SUPER FUND NAME": "FundName",
                "SUPER FUND ADDRESS": "FundAddress",
                "SUPER FUND TYPE": "SuperFundType",
                
                "CONTRIBUTION RATE": "",

                "MEMBER NO.": "MemberNumber",
                "SUPER FUND BANK NAME": "FundBankName",
                "SUPER FUND BANK BSB": "FundBankBSB",
                "SUPER FUND BANK ACCOUNT NO.": "FundBankNumber",
                "SUPER FUND USI": "FundUSI",
                "SUPER FUND ABN": "FundABN",
                "SUPER FUND EMPLOYER NO.": "FundEmployerNumber",
                "PAY RATE PER HOUR": "PayRatePerHour",

                "WEEKLY WORK HOURS": "WeeklyWorkHours"
            },
            leave: {
                "FIRST NAME": "FirstName",
                "LAST NAME": "LastName",
                "EMAIL": "Email",
                "CONTACT NUMBER": "ContactNumber",
                "PHONE": "ContactNumber",
            },
            payroll: {
                "FIRST NAME": "FirstName",
                "LAST NAME": "LastName",
                "EMAIL": "Email",
                "TFN": "TFN",
                "TAX NUMBER": "TFN",
                "TAX FILE NUMBER": "TFN",
                "CONTACT NUMBER": "ContactNumber",
                "PHONE": "ContactNumber",
            }
        }
      };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;
            
            this.file = file;

            // read first line
            this.fileFirstLine = '';

            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                this.fileFirstLine = text.split('\n')[0];
                this.createMappingTable();
            };
            
            reader.readAsText(this.file);

        },
        handleFieldSelection(columnName, selectedField) {
            this.inputColumns[columnName] = selectedField;
        },
        createMappingTable() {
            if (!this.file) {
                Utils.toastError('Please select a file to import');
                return;
            }

            if (!this.fileFirstLine){
                Utils.toastError('Please select a file to import');
                return;
            }

            const headings = this.fileFirstLine.split(this.selectedFieldDelimiter);

            this.inputColumns = headings.map(columnHeading => ({
                inputHeaderName: columnHeading,
                mappedFieldName: this.getBackendFieldName(columnHeading)
            }));
        },
        getBackendFieldName(columnHeading) {
            if (!columnHeading) return '';
            const upperCaseHeading = columnHeading.toUpperCase();
            return this.inputColumnMaps[this.selectedEntity][upperCaseHeading] || ''
        },
        clearFileInput() {
            this.$refs.fileInput.value = '';
            this.file = null;
            this.inputColumns = [];
            this.fileFirstLine = '';
        },
        async performImport() {
            if (!this.file) {
                Utils.toastError('Please select a file to import');
                return;
            }

            try {
                this.isImporting = true;

                const request = {
                    entity: this.selectedEntity,
                    companyId: Session.get("currenCompanyId"),
                    fieldSeparator: this.selectedFieldDelimiter,
                    columnFieldMapping: this.inputColumns
                };
                
                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('request', JSON.stringify(request));

                this.importErrors = '';

                this.importErrors = await ApiManager.postFormData('/api/imports', formData);
                
                if (!this.importErrors) {
                    Utils.toastSuccess('Import successful');
                    this.clearFileInput();
                }
                
            } catch (error) {
                Logs.logError('Error during import', error);
                Utils.toastError('Error during import');
            }
            finally {
                this.isImporting = false;
            }
        }
    },
    mounted() {
      // Code to run when the component is mounted
    }
  };
  </script>
  
  <style scoped>
 h6 {
    margin-top: 15px;
 }
 table td {
    vertical-align: top;
 }
 input {
    margin-bottom: 40px;
 }
 .alert {
    margin-top: 10px;
 }
  </style>